.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

ul.suggestions {
  display: block;
  position: absolute;
  left: 0;
  top: 2.4rem;
  z-index: 1000;
}

.suggestions {
  border: 1px solid #999;
  list-style: none;
  margin-top: 0;
  max-height: 400px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions li {
  background-color: #fff;
  padding: 0.5rem;
}

.suggestions li.suggestion-active,
.suggestions li:hover {
  background-color: #ceecf8;
  color: #000;
  cursor: pointer;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.input-group div {
  position: relative;
  min-width: 0;
}

.input-group div  input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1;
  font-family: inherit;
 
}
